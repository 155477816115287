import React from 'react'
import { Link } from 'react-router-dom'
import ShowMoreCurves from '../files/showMoreCurves'
import text_data from '../text_data.json'

const Introduction = () => {
  return (
    <div className='w-full mt-5 default-text'>
        <p className='whitespace-pre-line'>{text_data.vorstellung_startseite}</p>
        <div className='font-bold my-3 flex justify-center bg-white z-3'><Link to="/landesvorstand" ><p className='float-left'>Landesvorstand</p> <ShowMoreCurves className='h-4 sm:h-[18px] w-4 sm:w-[18px] mt-1 sm:mt-[5px] ml-1 float-left' /></Link></div>
    </div>
  )
}

export default Introduction