import React, { useEffect, useState, useLayoutEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Heading from '../components/heading'
import InputField from '../components/input_field'
import swal from 'sweetalert';
import text_data from '../text_data.json'

const Contact = () => {
    const [data, setData] = useState({name: "", e_mail: "", message: "", checked: false})
    const [completeData, setCompleteData] = useState(false)
    const navigate = useNavigate();


    useEffect(() => {
        document.title = "Kontakt - Liberale Schüler BaWü"
        window.scrollTo(0, 0)
    }, []);


    const submitData = () => {
        let formdata = new FormData()
        for ( var key in data ) {
            formdata.append(key, data[key]);
        }

        fetch("https://api.wrire.com/partner/ls-bawu/form", {
            method: "POST",
            body: formdata
        }).then((r) => {swal({
            title: "Vielen Dank",
            text: "Wir haben deine Anfrage erhalten und werden dir schnellstmöglich antworten",
            icon: "success",
            button: "Ok"
          }).then(e => window.location.href = "/home").catch(err => swal({
            icon: "error",
            title: "Ein Fehler ist aufgetreten. Schreibe uns eine Mail an kontakt@ls-bawu.de damit wir uns dem Problem annehmen können. Error " + err
        }))
    })}


    const handleSubmit = () => {
        if (data.e_mail !== "" && data.message !== "" && data.checked !== false){
            submitData()
        } else {
        swal({
            icon: "error",
            title: "Formular vollständig aufüllen"
        })
        }
    }
  return (
    <div className=''>
        <Heading w="w-16" text="Kontakt" centered={true} background={false}/>
        <p className='mt-4 default-text whitespace-pre-line'>
            {text_data.kontakt}
        </p>
        <InputField datapoint='Name' type='text' inputData={inputData => {setData({...data, name: inputData})}} />
        <InputField datapoint='E-Mail*' type='E-Mail' inputData={inputData => {setData({...data, e_mail: inputData})}} />
        <label className='font-semibold text-lg mt-3 inline-block'>Nachricht*</label>
        <textarea className='
        w-full mt-1 border border-gray-100
        p-3 bg-gray-100 focus:bg-white
        focus:outline-none focus:border-magenta min-h-[150px] duration-200'
        onChange={inputDataValue => setData({...data, message: inputDataValue.target.value})}

        />
        <div className='w-full inline-block'>
          <input type='checkbox' className="float-left mt-3 w-[18px] h-[18px] outline-none border border-gray-200 rounded-none" onChange={(e) => {setData({...data, checked: !data.checked})}} />
          <p className='w-[calc(100%-25px)] float-right mt-2 font-medium'>Mit der Nutzung dieses Formulars erklärst du dich mit der Speicherung und Verarbeitung deiner Daten durch diese Webseite einverstanden.</p>
        </div>
        <p className='text-sm sm:text-base text-gray-500 mt-2 mb-2'>* Pflichtfelder</p>
        <div className='w-full h-auto flex'><input type="submit" onClick={e => {handleSubmit()}} className='cursor-pointer bg-magenta text-white mx-auto px-4 py-1.5 text-lg font-semibold mt-2' value="Abschicken" /></div>
        
    </div>
  )
}

export default Contact