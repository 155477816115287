import React from 'react'

const Wartungsarbeiten = () => {

    return (
        <div className='absolute bg-magenta w-[100vw] h-[100vh] flex justify-center items-center left-0 top-0 z-[100]'>
            <div className='h-auto w-auto pl-2 flex flex-col'>
                <div><h1 className='text-3xl float-left bg-white px-2 py-1 font-extrabold text-black'>Wartungsarbeiten</h1></div>
                <p className='text-white mt-1'>Momentan finden Wartungsarbeiten an unserer Webseite statt. Komme zu einem späteren Zeitpunkt wieder.</p>
            </div>
        </div>
    )
}

export default Wartungsarbeiten