import React, { useEffect } from 'react'
import Heading from '../components/heading'
import PersonView from '../components/person_view'
import text_data from '../text_data.json'

const Landesvorstand = ({vorstandData, changeVorstandData}) => {

  useEffect(() => {
    document.title = "Landesvorstand - Liberale Schüler BaWü"
    window.scrollTo(0, 0)
    console.log(vorstandData)

    console.log("useEffect")

    const controller = new AbortController();
    const signal = controller.signal;

    if (vorstandData[0] === " "){
      fetch('https://api.wrire.com/partner/ls-bawu/Vorstand', { // fetch data from backend server
        method: 'GET',
        signal
      }).then((response) =>  response.json().then((response_data) => {
          changeVorstandData(response_data);
      })).catch(err => {console.log(err)})
    }

    return () => {controller.abort(); console.log("CANCELD")}
  }, []);

  useEffect(() => {
    console.log("vorstandData")
    console.log(vorstandData)
  }, [vorstandData])
  
  
  return (
    <div>
      <Heading text="Landesvorstand" centered={true} background={false}/>
      <p className='mt-6 default-text'>
        {text_data.landesvorstand}
      </p>
      <div className='w-full mt-16 relative'>
        {(vorstandData[0] !== " ") ? 
        <>
          {
            vorstandData.map((data, index) => {
              return <PersonView instagram={data.instagram} email={data.email} name={data.name} role={data.role} img={data.image_transparent} content={data.content} introduction={data.introduction} link={"/vorstand/" + data.name.toLowerCase() + '&' + index}  />
            })
          }
        </> : 
        <>
          <div className='mx-auto h-36 3xs:h-40 xs:h-36 sm:h-40 left-0 duration-500 flex bg-gradient-to-r from-magenta to-[#5800FF] w-[96%] xs:w-11/12 md:w-4/5 mt-10 skew-y-2 overflow-hidden xs:overflow-visible group'>
            <div className='loader'></div>
          </div>
          <div className='mx-auto h-36 3xs:h-40 xs:h-36 sm:h-40 left-0 duration-500 flex bg-gradient-to-r from-magenta to-[#5800FF] w-[96%] xs:w-11/12 md:w-4/5 mt-10 skew-y-2 overflow-hidden xs:overflow-visible group'>
            <div className='loader'></div>
          </div>
          <div className='mx-auto h-36 3xs:h-40 xs:h-36 sm:h-40 left-0 duration-500 flex bg-gradient-to-r from-magenta to-[#5800FF] w-[96%] xs:w-11/12 md:w-4/5 mt-10 skew-y-2 overflow-hidden xs:overflow-visible group'>
            <div className='loader'></div>
          </div>
          
        </>}
      </div>
    </div>
  )
}

export default Landesvorstand