import React, {useState} from 'react'
import {AiOutlineMail, AiOutlineTwitter, AiOutlineInstagram} from 'react-icons/ai'
import { Navigate } from 'react-router-dom'


const PersonView = ({name, role, img, email="", twitter, instagram, link=null, introduction="", marginTop=true }) => { 
  const [redirect, setRedirect] = useState(false)
  return (
      <>
        {(link != null && introduction != "") ? 
        <>
          <a href='#' onClick={e => {e.preventDefault(); setRedirect(true)}} className={`mx-auto relative h-36 3xs:h-40 xs:h-36 sm:h-40 left-0 flex justify-end  bg-gradient-to-r from-magenta to-[#5800FF] w-[96%] xs:w-11/12 md:w-4/5 mt-10 skew-y-2 overflow-hidden xs:overflow-visible group ${marginTop ? 'mt-10' : 'mt-5'}`}> 
            <PersonContainer name={name} role={role} img={img} email={email} twitter={twitter} instagram={instagram} link={true} />
          </a>
          {redirect && <Navigate to={link} />}
        
        </>:
        <div className={`mx-auto relative h-36 3xs:h-40 xs:h-36 sm:h-40 left-0 duration-500 flex justify-end  bg-gradient-to-r from-magenta to-[#5800FF] w-[96%] xs:w-11/12 md:w-4/5 skew-y-2 overflow-hidden xs:overflow-visible group ${marginTop ? 'mt-10' : 'mt-4 xs:mt-6 md:mt-8'}`}>
          <PersonContainer name={name} role={role} img={img} email={email} twitter={twitter} instagram={instagram} />
        </div>
      }

      </>
  )
}


 const PersonContainer = ({name, role, img, email, instagram, link=false}) => {

  return(
    <>
      <div className='absolute h-[105%] xs:h-[115%] left bottom w-full xs:w-[36%] sm:w-[35%] m-l:w-1/3 overflwo-hidden lg:w-[30%] xs:flex justify-center left-0 bottom-0'>
        <img src={img} className='opacity-70 xxs:opacity-100 h-full duration-200' />
      </div>
      <div className='float-right absolute xs:relative w-[90%] xs:w-[64%] sm:w-[65%] m-l:w-2/3 lg:w-[70%] flex justify-center h-full duration-200 z-20 right-0'>
      <div className='h-auto my-auto pb-2 pr-4 w-full xxs:w-full'>
        <div className='font-extrabold w-full leading-5 3xs:leading-6 text-xl 3xs:text-2xl md:text-3xl md:leading-7 text-white block duration-200'>
          <p className='float-right inline-block xs:float-left text-right xs:text-left'>{name}</p>
        </div>
      <div className=' 3xs:font-extrabold font-semibold w-full text-sm 3xs:text-lg text-white block leading-4 3xs:leading-5'>
        <p className='float-right xs:float-left inline-block w-full text-right xs:text-left 4xs:mt-1'>{role}</p>
      </div>
      <div className='inline-block w-full 3xs:mt-2 xs:float-left float-right'>
        {email != "" && <>{link ? <a onClick={e => {e.preventDefault(); window.open('mailto:' + email);} } href='#' ><AiOutlineMail size={28} className='float-right xs:float-left text-white ml-1.5 xs:ml-0 xs:mr-2' /></a> : <a href={`mailto:${email}`}><AiOutlineMail size={28} className='float-right xs:float-left text-white ml-1.5 xs:ml-0 xs:mr-2' /></a>}</>
        
        }
        </div>
        </div>
    </div>
    </>
  )
}

export default PersonView