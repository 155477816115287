import React, {useState, useEffect} from 'react'
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai'


const Event = ({Day, Month, EventName, Description}) => {
    const [showMore, setShowMore] = useState(false)
    
    return (
    <div onClick={e => {e.preventDefault(); if((Description !== null && Description !== "" && Description !== undefined)){ setShowMore(!showMore)}}}  
    className={`mb-8 relative w-full xs:w-11/12 h-auto sm:w-9/10 md:w-[85%] mx-auto ${(Description !== null && Description !== "" && Description !== undefined) && 'cursor-pointer'} skew-y-2  text-white duration-200`}>
        <div className='h-32 bg-gradient-to-r from-magenta to-[#5800FF] xs:h-36 md:h-40 overflow-hidden' >
            <div className='w-[100px] 3xs:w-[105px] xs:w-[120px] sm:w-[140px] h-full float-left flex justify-center items-center duration-200'>
                <div className='h-auto inline-block'>
                    <h1 className='text-[32px] leading-8 xxs:text-3xl xs:text-4xl font-extrabold text-accent-blue-2 w-full text-center inline-block '>{Day}</h1>
                    <h2 className='text-base xs:text-lg font-medium text-center w-full inline-block'>{Month}</h2>
                </div>
            </div>
             <div className='float-left w-[calc(100%-100px)]  3xs:w-[calc(100%-105px)] xs:w-[calc(100%-120px)] sm:w-[calc(100%-140px)] h-full flex items-center justify-between duration-200'>
                <h1 className='text-[15px] 4xs:text-base 3xs:text-lg xxs:text-xl md:text-2xl font-bold md:font-extrabold ml-2 max-w-[80%] duration-200'>{EventName}</h1>
                {(Description !== null && Description !== "" && Description !== undefined) && <><AiOutlinePlus  size={30} className={`mr-4 xs:mr-6 sm:mr-8 ${showMore && 'hidden'}`} />
                <AiOutlineMinus size={30} className={`mr-4 xs:mr-6 sm:mr-8 ${!showMore && 'hidden'}`} /></>}
            </div>
        </div>
        <div className={`text-[15px] bg-gradient-to-r from-magenta to-[#5800FF] xs:text-base px-5 xs:px-8  ${showMore ? 'max-h-96 py-5  xs:py-6 mt-2 opacity-100' : 'border-b-2 max-h-0 opacity-0 overflow-hidden'} duration-300`}>
            {Description}
        </div>
    </div>)
}


export default Event