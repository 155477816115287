import React, { useState, useEffect } from 'react'
import swal from 'sweetalert';
import { Navigate } from 'react-router-dom';
import text_data from '../text_data.json'

const LP_Input = ({inputData, type, placeholderValue}) => {
    return (
        <input type={type} onChange={inputDataValue => inputData(inputDataValue.target.value)}
        className='w-full mt-4 h-10 border border-gray-10 bg-[#F2F2F2] focus:bg-white px-3 focus:outline-none focus:border-magenta' 
        placeholder={placeholderValue}
        required 
        />
    )
}

const LP_BecomeMember = ({member}) => {
    const [data, setData] = useState({name: "", e_mail: "", street_and_number: "", zip_location:"", birth_date: "", schulende: "", phone_number:"", juli:true, checked: false})
    

    const submitData = () => {
        let formdata = new FormData()
        for ( var key in data ) {
            formdata.append(key, data[key]);
        }

        fetch("https://api.wrire.com/partner/ls-bawu/form", {
        method: "POST",
        body: formdata
        }).then((r) => r.json().then(data => {swal({
            title: "Vielen Dank",
            text: "Wir haben deine Anfrage erhalten und werden dir schnellstmöglich antworten",
            icon: "success",
            button: "Ok"
          })})).catch(err => swal({
            icon: "error",
            title: "Ein Fehler ist aufgetreten. Schreibe uns eine Mail an kontakt@ls-bawu.de damit wir uns dem Problem annehmen können. Error " + err
        }))
    }

    const handleSubmit = () => {
        if (data.name !== "" && data.e_mail !== "" && data.street_and_number !== "" && data.zip_location !== "" && data.birth_date !== "" && data.schulende !== "" && data.checked === true ){
            submitData()
        } else {
            swal({
                icon: "error",
                title: "Formular vollständig aufüllen"
            })
          }
    }
    

    return (
    <div className='block mt-2 md:mt-4'>
        <p className='inline-block w-full md:w-[55%] lg:w-3/5 default-text mt-1 md:mt-2 pr-4 whitespace-pre-line '>
            {text_data.become_member}
        </p>
        <div className='bg-white drop-shadow w-full xs:w-[80%] md:w-[45%] lg:w-2/5 md:float-right mx-auto px-6 md:px-4 pb-6 md:pb-4 pt-2 md:pt-0 mt-3 md:mt-0'>
            <LP_Input type="text" placeholderValue="Vor- und Nachname*" inputData={i => setData({...data, name: i})} />
            <LP_Input type="text" placeholderValue="Straße, Nr.*" inputData={i => setData({...data, street_and_number: i})}/>
            <LP_Input type="text" placeholderValue="PLZ, Ort*" inputData={i => setData({...data, zip_location: i})} />
            <LP_Input type="text" placeholderValue="E-Mail*" inputData={i => setData({...data, e_mail: i})} />
            <LP_Input type="text" placeholderValue="Geburtsdatum DD/MM/YYYY*" inputData={i => setData({...data, birth_date: i})} />
            <LP_Input type="number" placeholderValue="Voraussichtliches Schulende*" inputData={i => setData({...data, schulende: i})} />
            <LP_Input type="number" placeholderValue="Telefonnummer" inputData={i => setData({...data, phone_number: i})} />
            <div className='mt-3 text-sm xs:text-base md:text-sm'>
                <p className='ml-1 inline-block float-left'>Bereits JuLi-Mitglied?*</p>
                <input className='ml-3 inline-block mt-1 xs:mt-[5px] md:mt-1 float-left' type="radio" name="JuLi" onChange={i => setData({...data, juli: true})} />
                <p className='ml-1 inline-block float-left'>Ja</p>
                <input className='ml-2 md:ml-3 inline-block mt-1 xs:mt-[5px] md:mt-1 float-left' type="radio" name="JuLi" onChange={i => setData({...data, juli: false})} />
                <p className='ml-1 inline-block'>Nein</p>
            </div>
            <div className='mt-1 ml-1'>
                <input type="checkbox" checked={data.checked} onChange={e => setData({...data, checked: !data.checked})} className='float-left mt-1 mr-2 w-4 h-4 md:w-3 md:h-3 cursor-pointer' />
                <p className='text-sm xs:text-base md:text-sm'>Mit der Nutzung dieses Formulars erklärst du dich mit der Speicherung und Verarbeitung deiner Daten durch diese Webseite einverstanden.</p>
            </div>
            <div className='w-full flex justify-center mt-3 md:mt-2'>
                <input type="button" onClick={() => handleSubmit()} value="Mitglied werden" className='inline-block cursor-pointer bg-magenta text-white font-bold px-3 md:px-2 py-2 md:py-1' />
            </div>
        </div>
        
        
    </div>
  )
}

export default LP_BecomeMember