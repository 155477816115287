import React, {useEffect} from 'react'
import Heading from '../components/heading'

const Imprint = () => {
  useEffect(() => {
    document.title = "Datenschutzerklärung - Liberale Schüler BaWü"
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='sm:px-2 md:px-6 lg:px-10 default-text '>
        <Heading text="Impressum" />
        <h2>Angaben gemäß §5 TMG</h2>
        <p>Junge Liberale Baden-Württemberg e.V. <br />
        Rosensteinstraße 22 <br />
        70191 Stuttgart  <br />
        <h2>Vertreten durch und inhaltlich verantwortlich gem. § 55 Abs. 2 RStV</h2>
        Jan-Philipp Lenhardt <br />
        Anschrift wie oben
        </p>
        <h2>Kontakt</h2>
        Telefon: 01799331230 <br />
        E-Mail: kontakt@ls-bawu.de
        <h2>Quellenangaben für die verwendeten Bilder und Grafiken </h2>
        <p>Alle auf dieser Seite verwendeten Bilder und Grafiken unterliegen – sofern
      nicht explizit anders gekennzeichnet – der <a href='https://creativecommons.org/publicdomain/zero/1.0/'>CC0-Lizenz.</a> Sie sind für die 
      persönliche und kommerzielle Nutzung ohne die Angabe von Quellen
      freigegeben. Auf eine Nennung der Quellen wurde deshalb verzichtet.</p>
        <div className='w-full mt-6 inline-block'>
          <h2 className='text-black text-2xl sm:text-3xl'>Haftungsausschluss</h2>
        </div>
        <h2>Haftung für Inhalte</h2>
        <p>Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die
          Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine
          Gewähr übernehmen. Als Dienstanbieter sind wir gemäß § 7 Abs. 1 TMG für
          eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
          verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Dienstanbieter jedoch nicht
          verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
          überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
          Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
          Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon
          unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
          Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von
          entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
          entfernen.</p>
        <h2>Haftung für Links</h2>
        <p>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte
            wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch
            keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der
            jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten
            Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
            überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
            erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist
            jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar.
            Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links
            umgehend entfernen.</p>
        <h2>Urheberrecht</h2>
        <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten
          unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung,
          Verbreitung und jede Art der Verwertung außerhalb der Grenzen des
          Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors
          bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten,
          nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite
          nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
          beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet.
          Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden,
          bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
          Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>
        <h2>Streitbeilegung</h2>
        <p>Eventuelle Streitigkeiten möchten wir gerne einvernehmlich lösen und
            präferieren daher die direkte Kommunikation in einem solchem Fall.
            Zur Teilnahme am Streitschlichtungsverfahren sind wir nicht verpflichtet,
            weisen aber trotzdem auf die Plattform der Europäischen Kommission zur
            Online-Streitbeilegung unter <a href='http://ec.europa.eu/consumers/odr/'>http://ec.europa.eu/consumers/odr/</a> hin.</p>


    </div>
  )
}

export default Imprint