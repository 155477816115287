import React, {useState, useEffect} from 'react'
import Heading from '../components/heading'
import PersonView from '../components/person_view'
import ShowMore from '../files/showMore'
import text_data from '../text_data.json'

const Kreisverband = ({ data }) => {
    const [showMore, setShowMore] = useState(false)
    return(
    <div className='last-of-type:mb-12'>
        <a onClick={e => {e.preventDefault(); setShowMore(!showMore)}} href="#"><div className='w-full h-auto border-b-2 px-3 border-gray-400 mt-2'>
            <div className='w-full h-auto inline-block relative'>
                <h3 className='text-lg font-bold float-left max-w-[calc(100%-25px)]' >{data.verband_name}</h3>
                <ShowMore className={`right-0 absolute top-[calc(50%-9px)] ${showMore&&'rotate-90'} duration-200`} width={18} height={18} />
            </div>
        </div></a>
        
        <>
            <div className={`px-3 ${showMore ? 'mt-0 max-h-[1000px] opacity-100 mb-10' : 'max-h-0 opacity-0 overflow-hidden scale-50 '} default-text duration-500`}>
                {data.image !== "" && <img src={data.image} className='w-full aspect-video md:h-[26rem] mt-2 object-cover' />}
                <p className='mt-1'>{data.vorstellung}</p>
                <div className='my-1 float-left w-full flex gap-2'>
                    {(data.instagram !== "" ) && <a href={data.instagram} className="px-1.5 sm:px-2 py-0.5 sm:py-1 bg-magenta text-white font-semibold">
                        Instagram
                    </a>}
                    {(data.mail !== "" ) && <a href={"mailto:" + data.mail} className="px-1.5 sm:px-2 py-0.5 sm:py-1 bg-magenta text-white font-semibold">
                        E-Mail
                    </a>}
                </div>
                
                {(data.verantwortlicher_name !== "") &&
                <>
                    <h3 className='font-bold mt-2 inline-block'>Ansprechpartner:</h3>
                    <PersonView name={data.verantwortlicher_name} role={data.verantwortlicher_role} img={data.verantwortlicher_image} marginTop={false} />
                </>
                }
            </div>
        </>
    </div>
    )
}

const Kreisverbaende = ({kreisverbandData, changeData}) => {
    useEffect(() => {
        document.title = "Kreisverbände - Liberale Schüler BaWü"
        window.scrollTo(0, 0)

        const controller = new AbortController();
        const signal = controller.signal;

        if (kreisverbandData[0] == " "){
            fetch('https://api.wrire.com/partner/ls-bawu/Kreisverba',{ // fetch data from backend server
                method: 'GET',
                signal
            }).then((response) =>  response.json().then((response_data) => {
                changeData(response_data); console.log(response_data)
            })).catch(err => {console.log(err)})
        }

        return () => {controller.abort(); console.log("CANCELD")}

    }, [])

    return (
    <>
        <Heading text="Kreisverbände" />
        <p className='default-text mt-6'>{text_data.kreisverbaende} </p>
        <div className='h-auto w-full inline-block'><h1 className='default-text bg-magenta font-bold float-left px-[6px] py-[2px] text-white mt-4'>Bestehende Kreisverbände:</h1></div>

        {(kreisverbandData[0] !== " ") ? <>{kreisverbandData.map((data, index) => {
            return <Kreisverband data={data}  />
        })}</> : 
        <>
            <div className='loader mt-4'>
            </div>
        </>
        }
        
    </>
  )
}

export default Kreisverbaende