import React, {useEffect} from 'react'
import Heading from '../components/heading'
import '../imprint-datenschutz.css'

const Datenschutz = () => {
  useEffect(() => {
    document.title = "Datenschutzerklärung - Liberale Schüler BaWü"
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='default-text sm:px-2 md:px-6 lg:px-10'>
        <Heading text="Datenschutz" />
        <h2>Verantwortlicher</h2>
        <p>Im Folgenden informieren wir über Art, Umfang und Zwecke der Erhebung und Verarbeitung personenbezogener Daten bei Nutzung unserer Website.
        Verantwortlicher für die Datenverarbeitung gem. Art. 4 Abs. 7 EU-DatenschutzGrundverordnung (DSGVO) ist: <br />
        Jan-Philipp Lenhardt <br />
        Rosensteinstrasse 22 <br />
        70191 Stuttgart <br />
        E-Mail: Lenhardt@ls-bawu.de <br />
        Tel.: 01799331230 <br />
        </p>
        <h2>Grundsätze unserer Datenerhebung</h2>
        <p>Wir erheben in der Regel personenbezogenen Daten im Sinne von Art. 4 Nr. 1
        DSGVO: „Alle Informationen, die sich auf eine identifizierte oder identifizierbare
        natürliche Person (im Folgenden „betroffene Person“) beziehen; als
        identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt,
        insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer
        Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem oder
        mehreren besonderen Merkmalen, die Ausdruck der physischen,
        physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder
        sozialen Identität dieser natürlichen Person sind, identifiziert werden kann.“
        Solltest du unsere Homepage lediglich informatorisch nutzen, werden die im
        nächsten Abschnitt genannten Daten aufgrund von Art. 6 Abs. 1 S.1 lit. f DSGVO
        erhoben.
        Wenn du mit uns per E-Mail oder über Kontakt-, Mitgliedschafts- oder
        Serviceformulare Kontakt aufnehmen willst, werden die von dir übersandten
        Daten (E-Mail-Adresse, ggf. Name, Anschrift, Telefonnummer, Daten zur
        Kontoverbindung) von uns gespeichert, um die Anfrage zu beantworten. Die in
        diesem Zusammenhang anfallenden Daten löschen wir, nachdem die
        Speicherung nicht mehr erforderlich ist oder schränken die Verarbeitung ein,
        falls gesetzliche Aufbewahrungspflichten bestehen.</p>
        <h2>Hosting und Logfiles</h2>
        <p>
          Für das Betreiben der Webseite nutzen den Dienst des Webhosting Anbieters Netlify, 44 Montgomery Street, Suite 300, San Francisco, California 94104 (im folgenden „Netlify“). Es werden deshalb für das Betreiben der Webseite technisch notwendige Zugriffsdaten an Netlify übermittelt und in sogenannten „Logfiles“ bis zu 30 Tage lang gespeichert. Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. f) DSGVO. Wenn du mehr über die Verarbeitung von personenbezogenen Daten durch Netlify erfahren möchtest, kannst du dies der Privacy Policy auf <a href="https://netlify.com/privacy/">https://netlify.com/privacy/</a> entnehmen. <br /> <br />
          
          Netlify verwendet sogenannte Standardvertragsklauseln der EU-Kommission nach Art. 46 Abs. 2 und 3 DSGVO, womit das hohe Schutzniveau der DSGVO gewährleistet wird. Die Standardsvertragsklauseln können Sie unter https://netlify.com/legal/netlify-dpa.pdf einsehen.
        </p>
        <h2>Server</h2>
        <p>Zur Bereitstellung der Inhalte wird auf einem VPS bei bei Digital Ocean LLC gehostet ist. <br /> <br />

          Diese Webseite nutzt virtuelle Server über eine Cloud-Plattform von Digital Ocean LLC, 106 6th Avenue, New York USA.
          Der Serverstandort ist ein ISO/IEC 27001 zertifiziertes Rechenzentrum in Frankfurt, in welchem Digital Ocean Stellplätze anmietet. <br /> <br />

          DigitalOcean wird aufgrund unserem berechtigen Interesse, ein möglichst fehlerfreies und sicheres Webangebot aufrecht erhalten zu können, eingesetzt (Art. 6 Abs. 1 lit. f DSGVO). <br /> 
          Datenübertragungen in die USA wird auf Basis Standardvertragsklauseln der EU-Kommission durchgeführt. Details hierzu:<br />
          <a href='https://www.digitalocean.com/legal/privacy-policy/'>https://www.digitalocean.com/legal/privacy-policy/</a>
        </p>
        <h2>Weitergabe von Daten</h2>

        <p>Eine Übermittlung Deiner personenbezogenen Daten an Dritte findet grundsätzlich nicht statt, außer in den folgenden gesetzlichen Ausnahmen:</p>
        <ul className='ul' style={{ listStyleType: 'disc' }} >
          <li className='li'>Du hast nach Art. 6 Abs.1 S.1 lit. a DSGVO Deine ausdrückliche Einwilligung dazu erteilt,</li>
          <li className='li'>die Weitergabe ist nach Art. 6 Abs.1 S.1 lit. f DSGVO zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich und es besteht kein Grund zur Annahme, dass du ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe deiner Daten haben,</li>
          <li className='li'>es besteht eine gesetzliche Verpflichtung für die Weitergabe nach Art. 6 Abs. 1 S.1lit. c DSGVO, sowie</li>
          <li className='li'>wenn dies gesetzlich zulässig und nach Art. 6 Abs.1 S.1 lit. b DSGVO für die Abwicklung von Vertragsverhältnissen mit dir erforderlich ist.</li>
        </ul>

        <h2>Datensicherheit</h2>
        <p>Deine Daten werden durch geeignete technische und organisatorische
          Sicherheitsmaßnahmen bei uns gespeichert und gegen zufällige oder vorsätzliche
          Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen
          den unbefugten Zugriff Dritter geschützt. Diese Maßnahmen entsprechen dem
          Stand der Technik. <br /><br />
          Für die Verschlüsselung der Website verwenden wir eine SSL-Verschlüsselung.
          Ob eine Seite verschlüsselt übertragen wird, erkennst du an der Darstellung des
          Schlüssel- bzw. des geschlossenen Schloss-Symbols in der Statusleiste deines
          Browsers. Wenn du darauf klickst, erhältst du auch weitere Informationen zum
          hierfür verwendeten Zertifikat.
          </p>

        <h2>Deine Rechte</h2>
        <p>Du hast gegenüber uns folgende Rechte hinsichtlich der dich betreffenden
        personenbezogenen Daten:
        <ul className='ul' style={{ listStyleType: 'disc' }}>
          <li className="li">Recht auf Auskunft nach Art. 15 DSGVO;</li>
          <li className="li">Recht auf Berichtigung nach Art. 16 DSDVO;</li>
          <li className="li">Recht auf Löschung nach Art. 17 DSGVO, soweit nicht die Verarbeitung zur
          Ausübung des Rechts auf freie Meinungsäußerung und Information, zur
          Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen
          Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen erforderlich ist;</li>
          <li className="li">Recht auf Einschränkung der Verarbeitung nach Art. 18 DSGVO, wenn die
          Richtigkeit der Daten von dir bestritten wird; wenn die Verarbeitung
          unrechtmäßig ist, du jedoch statt Löschung eine Einschränkung verlangst; wenn
          wir die Daten nicht mehr benötigen, du dennoch diese zur Geltendmachung,
          Ausübung oder Verteidigung von Rechtsansprüchen benötigst oder du gemäß
          Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt hast;</li>
          <li className="li">Recht auf Widerspruch gegen die Verarbeitung gemäß Art. 21 DSGVO;</li>
          <li className="li">Recht auf Datenübertragbarkeit nach Art. 20 DSGVO.</li>
        </ul>
        Du hast zudem das Recht, dich nach Art. 77 DSGVO bei einer DatenschutzAufsichtsbehörde über die Verarbeitung deiner personenbezogenen Daten durch
        uns zu beschweren.</p>

        <h2>Widerspruchsrecht</h2>
        <p>Das Recht auf Widerspruch umfasst gemäß Art. 21 DSGVO insbesondere die folgenden Fälle:</p>
        <ul className='ul' style={{ listStyleType: 'disc' }}>
          <li className='li'>Eine eventuell erteilte Einwilligung gemäß 7 Abs. 3 DSGVO kannst du jederzeit widerrufen.</li>
          <li className='li'>Sollten deine Daten aufgrund der Vertragserfüllung erhoben und verarbeitet worden sein, ist auch ein Widerruf möglich. Einer möglichen Löschung der Daten stehen eventuell gesetzliche Vorgaben entgegen.</li>
          <li className='li'>Sollte sich die Datenverarbeitung auf eine Interessenabwägung stützen, kannst
          du Widerspruch gegen die Verarbeitung einlegen. Dies ist der Fall, wenn die
          Verarbeitung insbesondere nicht zur Erfüllung eines Vertrags mit dir erforderlich
          ist, was jedoch jeweils gesondert dargelegt wird. Bei Ausübung eines solchen
          Widerspruchs bitten wir um Darlegung der Gründe, weshalb wir deine
          personenbezogenen Daten nicht wie von uns durchgeführt verarbeiten sollten. In
          diesem Fall prüfen wir die Sachlage und werden entweder die Datenverarbeitung
          einstellen bzw. anpassen oder dir unsere zwingenden schutzwürdigen Gründe
          aufzeigen, aufgrund derer wir die Verarbeitung fortführen.</li>
          <li className='li'>Der Verarbeitung deiner personenbezogenen Daten für Zwecke der Werbung und
              Datenanalyse kannst du selbstverständlich jederzeit widersprechen. Nutze
              hierfür die Kontaktdaten im ersten Abschnitt dieser Datenschutzerklärung.
            </li>

        </ul>


    </div>
  )
}

export default Datenschutz