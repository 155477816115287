import './index.css';
import React, { useState, createContext, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import BecomeMember from './pages/become_member';
import Events from './pages/events';
import Landesvorstand from './pages/landesvorstand';
import LandingPage from './pages/landing_page';
import OurPositions from './pages/our_positions';
import News from './pages/news';
import PersonIntroduction from './pages/person_introduction';
import Contact from './pages/contact';
import PositionSide from './pages/position_side';
import ArticleSide from './pages/article_side';
import { EventContext, PositionContext, DataContext, VorstandContext } from './context';
import Kreisverbaende from './pages/kreisverbaende';
import Imprint from './pages/imprint';
import Datenschutz from './pages/datenschutz';
import Wartungsarbeiten from './pages/wartungsarbeiten';

function App() {
  const [data, setData] = useState(["", "", ""])
  const [eventData, setEventData] = useState([" "])
  const [positionData, setPositionData] = useState([" "])
  const [vorstandData, setVorstandData] = useState([" "])
  const [kreisverbandData, setKreisverbandData] = useState([" "])
  const [footer, setFooter] = useState(true)
  const [landscape, setLandscape] = useState(false)
  const [xxl, setXXL] = useState(false)

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetch('https://api.wrire.com/partner/ls-bawu?page=0', { // fetch data from backend server
        method: 'GET',
        signal
    }).then((response) =>  response.json().then((response_data)=>{
        setData(response_data)
    })).catch(err => {console.log(err)})
    
    fetch('https://api.wrire.com/partner/ls-bawu/Events', { // fetch data from backend server
        method: 'GET',
        signal
    }).then((response) =>  response.json().then((response_data) => {
        setEventData(response_data)
    })).catch(err => {console.log(err)})

    fetch('https://api.wrire.com/partner/ls-bawu/Positionen', { // fetch data from backend server
        method: 'GET',
        signal
    }).then((response) =>  response.json().then((response_data) => {
        setPositionData(response_data)
    })).catch(err => {console.log(err)})


    return () => {controller.abort(); console.log("canceled")}
  }, [])
  

  return (
    <Router>
      <DataContext.Provider value={data}>
        <EventContext.Provider value={eventData} >
        <PositionContext.Provider value={positionData} >
          <Header />
          <div className='min-h-[calc(100vh-200px)] h-full font-["Montserrat"] bg-["#FDFDFD"] max-w-[60rem] w-[90%] z-1 mx-auto mt-[5.5rem] pb-4'>
            <div className='inline-block min-h-full mt-[1.5rem] w-full h-auto float-left last:mb-8'>
              <Routes>
                <Route path="/home" element={<LandingPage  />} /> 
                <Route path="/mitglied-werden" element={<BecomeMember unmountFooter={() => setFooter(false)} mountFooter={() => setFooter(true)}  />} />
                <Route path="/events" element={<Events />}  />
                <Route path="/landesvorstand" element={<Landesvorstand vorstandData={vorstandData} changeVorstandData={e => {console.log(e); setVorstandData(e)}} />} />
                <Route path="/vorstand/">
                  <Route path=':vorstandID' element={<PersonIntroduction vorstandData={vorstandData} changeVorstandData={e => setVorstandData(e)} landscape={e =>setLandscape(true)} NoLandscape={e => setLandscape(false)} />} />
                </Route>
                <Route path="/neuigkeiten/">
                  <Route path=':newsID' element={<ArticleSide unmountFooter={() => setXXL(true)} mountFooter={() => setXXL(false)} />} />
                </Route>
                <Route path="/positionen" element={<OurPositions  />} />
                <Route path="/position/">
                  <Route path=':positionID' element={<PositionSide unmountFooter={() => setFooter(false)} mountFooter={() => setFooter(true)} />} />
                </Route>
                <Route path="/news" element={<News />} />
                <Route path="/kreisverbaende" element={<Kreisverbaende kreisverbandData={kreisverbandData} changeData={d => setKreisverbandData(d)} />} />
                <Route path="/impressum" element={<Imprint />} />
                <Route path="/datenschutz" element={<Datenschutz />} />
                <Route path="*" element={<Navigate to="/home" replace />} />
                <Route path="/kontakt" element={<Contact />}   />
                <Route path="/wartungsarbeiten" element={<Wartungsarbeiten unmountFooter={() => setFooter(false)} mountFooter={() => setFooter(true)} />}   />
              </Routes>
            </div>
          </div>
          {footer && <Footer landscape={landscape} xxl_hidden={xxl} />}
          

        </PositionContext.Provider>
        </EventContext.Provider>
      </DataContext.Provider>
    </Router>
  );
}

export default App;
