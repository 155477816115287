import React, {useState, useEffect} from 'react'
import Footer from '../components/footer'
import Heading from '../components/heading'
import InputField from '../components/input_field'
import swal from 'sweetalert'
import text_data from '../text_data.json'
import  LS_mitgliedwerden from '../files/ls_mitgliedwerden.jpeg'


const BecomeMember = ({ unmountFooter, mountFooter}) => {
  const [data, setData] = useState({Name: "", E_Mail: "", Strasse_und_Nummer: "", PostleitZahl:"", Wohnort: "", Geburtsdatum: "", Schulende: "", PhoneNumber:"", JuLi:true, checked: false})
  const [success, setSuccess] = useState(false)
  const [sent, setSent] = useState(false)

  useEffect(() => {
    document.title = "Mitmachen - Liberale Schüler BaWü"
    window.scrollTo(0, 0)

    unmountFooter()

    return () => {mountFooter()}
  }, []);
  

  const submitData = () => {
    let formdata = new FormData()
    for ( var key in data ) {
        formdata.append(key, data[key]);
    }




    fetch("https://api.wrire.com/partner/ls-bawu/form", {
    method: "POST",
    body: formdata
    }).then((r) => {setSuccess(true)}).catch(err => swal({
        icon: "error",
        title: "Ein Fehler ist aufgetreten. Schreibe uns eine Mail an kontakt@ls-bawu.de damit wir uns dem Problem annehmen können. Error " + err
    }))
  }

    const handleSubmit = () => {
      setSent(true)
      if (data.name !== "" && data.e_mail !== "" && data.street_and_number !== "" && data.zip !== "" && data.location !== "" && data.birth_date !== "" && data.schulende !== "" && data.checked === true ){
        console.log(data)  
        submitData()
      } else {
        swal({
          icon: "error",
          title: "Formular vollständig aufüllen"
        })
      }
    }
  
  
  return (
    <>
      {!(sent == true && success == false) && 
      <img className={`fixed w-full -z-20 left-0 top-[5.5rem] ${!success ? 'h-52 3xs:h-64 xs:h-80 md:h-96' : 'h-[calc(100vh-5.5rem)]'} object-cover duration-200`} src={LS_mitgliedwerden} />}
      
      {!sent ? <div className='absolute left-0 w-full bg-white z-10 mt-52 3xs:mt-64 xs:mt-80 md:mt-96'>
        <div className='mx-auto max-w-[60rem] w-[90%]'>
          <Heading text="Mitglied werden" centered={true} background={false}/>
          <p className='mt-2 sm:mt-4 default-text whitespace-pre-line'>
            {text_data.become_member}
          </p>
          <InputField datapoint={"Vor- und Nachname*"} type="text" inputData={i => setData({...data, Name: i})} />
          <InputField datapoint={"E-Mail*"} type="E-Mail" inputData={i => setData({...data, E_Mail: i})} />
          <InputField datapoint={"Straße, Hausnr.*"} type="text" inputData={i => setData({...data, Strasse_und_Nummer: i})} />
          <InputField datapoint={"Wohnort*"} type="text" inputData={i => setData({...data, Geburtsdatum: i})} />
          <InputField datapoint={"Postleitzahl*"} type="text" inputData={i => setData({...data, PostleitZahl: i})} />
          <InputField datapoint={"Geburtsdatum*"} type="date" inputData={i => setData({...data, Geburtsdatum: i})} />
          <InputField datapoint={"Voraussichtliches Schulende*"} type="text" inputData={i => setData({...data, Schulende: i})} />      
          <InputField datapoint={"Telefonnummer"} type="text" inputData={i => setData({...data, PhoneNumber: i})} />
          <div>
            <p className='font-semibold text-lg mt-3 inline-block'>Bereits JuLi Mitglied?*</p>
            <select onChange={i => setData({...data, Juli: i.target.value})} className='w-full mt-1 h-12 border bg-gray-100 focus:bg-white px-3 border-gray-100 outline-none focus:border-magenta duration-200'>
              <option value="Ja" >Ja</option>
              <option value="Nein">Nein</option>
            </select>
          </div>
          <div className='w-full inline-block'>
              <input type='checkbox' className="float-left mt-5 w-[18px] h-[18px] outline-none border border-gray-200 rounded-none" onChange={e => {setData({...data, checked: !data.checked})}} />
              <p className='w-[calc(100%-28px)] float-right mt-4 font-medium'>Mit der Nutzung dieses Formulars erklärst du dich mit der Speicherung und Verarbeitung deiner Daten durch diese Webseite einverstanden. </p>
          </div>
          <p className='text-sm sm:text-base text-gray-500 mt-2 mb-2'>* Pflichtfelder</p>
          <input type="submit" onClick={e => {e.preventDefault(); handleSubmit()}} className='cursor-pointer mb-8 mx-auto block bg-magenta text-white px-4 py-1.5 text-lg font-semibold mt-2 hover:drop-shadow-lg' value="Mitglied werden" />
        </div>
        <Footer />
      </div> :
      <>{
       <><div className={`absolute max-w-[90%] ${success?"scale-100":"scale-90 hidden"} w-96 h-auto px-4 py-6 bg-white drop-shadow-lg top-32 duration-500`}>
          <h1 className='font-extrabold text-xl 3xs:text-2xl '>Dein Mitgliedsantrag ist bei uns eingegangen.</h1>
          <p className='default-text mt-1'>Danke, dass du uns dabei helfen möchtest, weltbeste Bildung für jeden zu ermöglichen. Wir werden uns schon bald mit dir in Verbindung setzen.</p>
        </div>
        <div className={`absolute h-full w-full left-0 top-0 flex ${success && "hidden"}`}>
          <div className='loader'></div>
        </div> </>

        }
      </>
    }
    </>

  )
}

export default BecomeMember