import React, { useEffect, useState, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Footer from '../components/footer'
import { LoadingText } from './article_side'


const PersonIntroduction = ({landscape, NoLandscape, vorstandData, changeVorstandData }) => {
    let navigate = useNavigate()
    let { vorstandID } = useParams()

    const [id, setID] = useState(0)
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        document.title = "Landesvorstand - Liberale Schüler BaWü"
        window.scrollTo(0, 0)


        let newID = vorstandID.split("&")[1]
        setID(parseInt(newID))
    
        landscape()

        const controller = new AbortController();
        const signal = controller.signal;

        if (vorstandData[0] === " "){
            fetch('https://api.wrire.com/partner/ls-bawu/Vorstand', { // fetch data from backend server
                method: 'GET',
            }).then((response) =>  response.json().then((response_data) => {
                changeVorstandData(response_data);
            })).catch(err => {console.log(err)})
            }

        return() => {NoLandscape(); controller.abort(); console.log("CANCELD")}
    }, []);

    useEffect(() => {
      if (vorstandData[0] !== " "){setLoading(false)}
    }, [vorstandData])
    
    return (
        <div className='w-full inline-block mt-4 '>
            <div className='landscape:sm:w-[36%] landscape:lg:w-2/5 float-left'>
                {!loading ? 
                <img src={vorstandData[id].image} className='
                fixed top-[5.5rem] left-0 landscape:relative landscape:top-0 h-[calc(100%-5.5rem)] landscape:h-auto w-full
                portrait:aspect-auto 
                xs:aspect-3/4
                select-none object-cover' /> : 
                <div className='fixed top-[5.5rem] left-0 landscape:relative landscape:top-0 h-[calc(100%-5.5rem)] landscape:h-auto portait:w-full
                portrait:aspect-auto flex portrait:w-full
                landscape:xs:aspect-4/3 bg-gray-100 landscape:sm:aspect-3/4 select-none
                object-cover'>
                    <div className='loader'></div>
                </div>}
                <div className='portrait:absolute top-24 flex portrait:flex-col flex-row gap-2 pt-2 landscape:w-full font-semibold'>
                        {(vorstandData[id].instagram !== "") && <a href={vorstandData[id].instagram} className="portrait:mr-2 text-left">
                            <p className='px-2 py-1 bg-magenta text-white float-left'>Instagram</p>
                        </a>}
                        {(vorstandData[id].mail !== "") && <a href={"mailto:" + vorstandData[id].mail} className="text-left">
                            <p className='px-2 py-1 bg-magenta text-white float-left'>E-Mail</p>
                        </a>}
                </div>
            </div>
            <div className='absolute bottom-8 flex flex-col landscape:hidden portait:xs:hidden py-2'>
                {!loading ? 
                <><div className='w-full'><h2 className='bg-magenta text-white font-bold px-2 py-[2px] float-left'>{ vorstandData[id].role }</h2></div>
                <div className='w-full'><h1 className='font-extrabold text-2xl bg-white text-black px-2 py-1 float-left'>{ vorstandData[id].name }</h1></div></>
                : 
                <>
                    <div className='animation-magenta h-7 w-52'></div>
                    <div className='animation-darker h-9 w-80'></div>
                </>

            }
            </div>
            <div className='absolute bg-white portrait:w-full left-0 landscape:xs:relative top-[100vh] landscape:xs:top-0 landscape:w-full landscape:xs:w-[64%] landscape:lg:w-3/5 float-right pl-0 landscape:sm:px-4 landscape:md:px-5'>
                <div className='portrait:max-w-[60rem] portrait:w-[90%] mx-auto h-auto block'>
                {!loading ? <><h2 className='font-bold hidden landscape:xs:block leading-5 xs:text-xl text-magenta  mt-5 xxs:mt-3 xs:mt-2 sm:mt-0'>{vorstandData[id].role}</h2>
                    <h1 className='font-extrabold hidden landscape:xs:block 3xs:text-2xl sm:text-[27px]'>{vorstandData[id].name}</h1>
                    <p className='default-text float-left portrait:mt-5 mt-1 sm:mt-2 whitespace-pre-line md:text-justify w-full portrait:pb-8 hyphens '>{vorstandData[id].introduction}</p>

                </> : 
                <>
                    <div className='animation h-7 w-52 rounded'></div>
                    <div className='animation mt-1 h-10 w-96 rounded'></div>
                    <LoadingText x="left-[15%]" y="left-[50%]" z="left-[90%]" />
                    <LoadingText x="left-[20%]" y="left-[40%]" z="left-[80%]" />
                    <div className='w-2/3'><LoadingText x="left-[20%]" y="left-[50%]" z="left-[70%]" /></div>
                    <div className='w-2/5'><LoadingText /></div>
                </>}
                </div>
                <Footer portrait={true} />
            </div>

        </div>
  )
}

export default PersonIntroduction
