import React, { useEffect, useContext } from 'react'
import PositionView from '../components/position_view'
import Heading from '../components/heading'
import { PositionContext } from '../context'
import text_data from '../text_data.json'

var Grundsatzprogramm = require('../files/Grundsatzprogrammls.pdf')

const OurPositions = () => {
  const position_data = useContext(PositionContext)

  useEffect(() => {
    document.title = "Landesvorstand - Liberale Schüler BaWü"
    window.scrollTo(0, 0)
  }, []);

  useEffect(() => {
    console.log(position_data)
  }, [position_data]);

  const LoadView = () => {
    return(
    <div className='relative flex w-auto bg-gray-100 h-auto group aspect-4/3 3xs:aspect-video xs:aspect-4/3 md:aspect-video rounded-sm shadow-md shadow-zinc-300'>
      <div className='loader'></div>
    </div>)
  }

  return (
    <>
    <Heading text="Unsere Positionen" centered={true} background={false} />
    <p className='default-text mt-4'>
      {text_data.our_positions}
    </p>
    <div className='mt-6 grid grid-cols-1 xs:grid-cols-2 gap-3'>
      {(position_data[0] !== " ") ?
        <>{position_data.map((data, index) => {
          return(<PositionView key={index} topic={data.topic} image={data.picture} link={"/position/" + data.topic.toLowerCase() + "&" + index} lp={false} />)
        })}</> :
        <><LoadView /><LoadView /><LoadView /><LoadView /></>
        
      }
    </div>
    <div className='w-full mt-6 flex'>
      <a href={Grundsatzprogramm} className="bg-gradient-to-r rounded from-magenta to-[#5800FF] text-white font-bold text-sm 3xs:text-base xs:text-lg md:text-xl px-2 py-1 mx-auto">Grundsatzprogramm herunterladen</a>
    </div>
    </>
  )
}

export default OurPositions